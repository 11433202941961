import './bootstrap';
import '../css/app.css';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

// FORM SUBMIT BUTTON ANIMATION
const formSubmitBTN = document.querySelectorAll('form button[type="submit"]')

if(formSubmitBTN) {
    formSubmitBTN.forEach(button => {
        const roundedClassess = button.classList.contains('rounded-full') ? 'rounded-full' : 'rounded-md'
        
        button.addEventListener('click', function() {
            button.innerHTML = `<div class="w-full h-full flex justify-center items-center ${roundedClassess}">
                    <iconify-icon icon="line-md:loading-twotone-loop" class="text-xl"></iconify-icon>
                </div>`
    
            setTimeout(() => {
                button.parentElement.innerHTML += `<div class="w-full h-full bg-slate-100 bg-opacity-75 absolute inset-0 z-20 cursor-not-allowed"></div>`
            }, 50);
        })
    })
}

// INPUT ERROR HANDLER
const inputGroup = document.querySelectorAll('#input-group')

if (inputGroup) {
    inputGroup.forEach(input => {
        const inputElement = input.querySelector('input, select, textarea')
        const errorMessage = input.querySelector('#input-error')
    
        inputElement.addEventListener('input', function() {
            if(errorMessage) {
                errorMessage.remove()
            }
        })
    })
}
